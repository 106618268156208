
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        














.text {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 28px,
      xl: 48px,
    )
  );

  margin-bottom: 3rem;
  color: $tango;
  line-height: 1.3;

  @include mq(xl) {
    max-width: inside-col(15);
    margin-bottom: 1rem;
    line-height: 1.2;
  }
}

.author {
  display: inline-block;
  margin-right: 1rem;
  color: $tango;
}

.footer {
  text-align: right;
  font-size: 1.5rem;
}
