
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



























































.cta {
  margin: 0;

  & + .textSlider {
    margin-top: 0;
  }
}

.productsList {
  @include mq(xxl) {
    .wrapper {
      padding: 0;
    }
  }
}
