
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





















.scrolling-gallery,
[class*='scrolling-gallery--'] {
  position: relative;
  height: 30rem;

  &[class*='--overlaped'] {
    z-index: 10;
    margin-top: -2rem;
    margin-bottom: -3rem;
  }

  @include mq(m) {
    height: 50rem;

    &[class*='--overlaped'] {
      margin-top: -8rem;
      margin-bottom: 0;
    }
  }
}

.picture {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 15rem;
  max-width: 30rem;
  height: auto;
  min-height: 10rem;
  max-height: 15rem;
  margin: 0;
  will-change: transform;

  img {
    @include image-fit;
  }

  @include mq(l) {
    max-width: none;
    max-height: none;
  }
}

.picture-0 {
  top: 0%;
  width: 25%;
  height: 90%;
}

.picture-1 {
  top: 45%;
  width: 15%;
  height: 50%;
}

.picture-2 {
  top: 10%;
  width: 25%;
  height: 60%;
}

.picture-3 {
  top: 40%;
  width: 15%;
  height: 60%;
}

.picture-4 {
  top: 20%;
  width: 20%;
  height: 90%;
}

.picture-5 {
  top: 10%;
}
