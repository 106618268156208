
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




















































.product-list {
  position: relative;

  @include mq(xxl) {
    display: flex;
  }
}

.preview {
  display: none;

  @include mq(xxl) {
    display: block;
    flex-shrink: 0;
    width: 50%;
    padding-top: 50%;
  }
}

.list {
  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  @include mq(xxl) {
    align-items: stretch;
  }
}

.list-wrapper {
  @include mq(xxl) {
    display: flex;
    align-items: center;
    margin: 0 0 0 col(1);
    padding: 10rem 0;
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $c-gray-light;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 3rem;
    right: 0;
    display: none;
    width: 1rem;
    height: 1px;
    background: $c-black;
    transition: transform 0.2s;
  }

  &::after {
    transform: rotate(90deg);
  }

  &.is-open {
    &::after {
      transform: rotate(0);
    }
  }

  @include mq($until: xxl) {
    position: relative;

    &::before,
    &::after {
      display: block;
    }
  }

  @include mq(s) {
    width: calc(50% - 2rem);
  }

  @include mq(xxl) {
    margin: 0 1rem 1.5rem;
  }
}

.info {
  display: flex;
  align-items: flex-end;
}

.small-picture {
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;

  img {
    width: 100%;
  }
}

.title {
  @include fluid(
    font-size,
    (
      mob: 15px,
      xl: 18px,
    )
  );

  display: flex;
  font-size: 1.5rem;
  font-weight: 300;
  white-space: nowrap;

  .is-open & {
    color: $tango;
  }

  sup {
    margin: 0 0 1rem 0.5rem;
    color: $monza;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
}

.picture {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  height: 30rem;
  max-height: 0;
  transition: max-height 0.25s;

  .is-open & {
    max-height: 30rem;
  }

  img {
    @include image-fit;

    margin-top: 1rem;
  }

  @include mq(xxl) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 50%;
    height: 100%;
    max-height: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;

    .is-first:not(.is-open) &,
    .is-open & {
      max-height: none;
      opacity: 1;
      visibility: visible;
    }

    .is-open & {
      z-index: 1;
    }

    img {
      margin-top: 0;
    }
  }
}

.picture__info {
  position: relative;
  z-index: 1;
  padding: col(1);
  color: $c-white;

  p {
    line-height: 1.2;
  }

  @include mq(xxl) {
    position: absolute;
    bottom: 0;
  }

  @include mq($until: xxl) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 1rem);
    background-image: linear-gradient(
      to top,
      rgba($c-black, 0.8),
      rgba($c-black, 0.1)
    );
  }
}

.picture__info__title {
  margin-bottom: 1rem;
}
