
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
































.cards-list {
  overflow: hidden;
}

.title {
  display: block;
  margin-bottom: 5rem;
}

.cards {
  display: flex;
  flex-wrap: wrap;

  .card {
    flex-shrink: 0;
    width: 100%;
    margin: 1rem;
    pointer-events: none;
  }

  @include mq(xs) {
    margin: 0 -1rem;

    .card {
      display: block;
      width: calc(50% - 2rem);
      margin: 1rem;
    }

    ::v-deep .card__content {
      padding-left: 0;
    }
  }

  @include mq(l) {
    .card {
      width: calc(25% - 2rem);
    }
  }
}

.slider {
  margin-top: 0;

  ::v-deep .card {
    display: flex;
    pointer-events: none;
  }

  @include mq(xs) {
    margin: 0 -1rem;

    ::v-deep .card {
      display: block;
      width: calc(50% - 2rem);
      margin: 1rem;
    }

    ::v-deep .card__content {
      padding-left: 0;
    }
  }

  @include mq(s) {
    ::v-deep .card {
      max-width: 30rem;
    }
  }

  @include mq($from: xs, $until: s) {
    ::v-deep .updates__container {
      display: flex;
      flex-wrap: wrap;

      .card:nth-child(1),
      .card:nth-child(2),
      .card:nth-child(3) {
        display: block;
      }
    }
  }
}
