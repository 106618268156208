
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
















































.text-slider {
  position: relative;
  background: $seashell-peach;
}

.banner {
  position: absolute;
  top: 0;

  @include mq($until: xl) {
    width: 3rem;
  }
}

.inner {
  position: relative;
}

.title {
  // prettier-ignore
  @include fluid(font-size, (mob: 40px, xl: 80px));

  margin-bottom: 0;
  line-height: 1.2;
  background-clip: text;
  background-size: 200% 200%;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $c-black;
  transition: background-color 0.5s;

  .is-active &,
  &:hover {
    cursor: pointer;
    background-color: $c-black;
    // -webkit-text-fill-color: $c-black;
  }

  @include mq(xl) {
    margin-bottom: -1rem;
  }
}

.text {
  @include mq($until: m) {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;

    .is-active & {
      max-height: 30rem;
      transition: max-height 1s 0.5s;
    }
  }

  @include mq(m) {
    position: absolute;
    top: 10%;
    right: 0;
    width: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;

    .is-active & {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mq(m) {
    width: inside-col(7, 20);
  }

  @include mq(xxxl) {
    width: inside-col(7);
  }
}

.text-inner {
  margin-top: 2rem;
  margin-bottom: 3.5rem;

  @include mq(m) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nav {
  @include fluid(
    margin-top,
    (
      mob: 25px,
      xl: 85px,
    )
  );

  position: relative;
  display: flex;
  width: 100%;
  height: 2px;
}

.nav-item {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 50rem;
  height: 100%;
  background: linear-gradient(to right, $tango 0 50%, $bone 50% 100%);
  background-size: 200%;
  background-position-x: 100%;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.cta {
  margin-top: 3rem;
}
